<template>
    <section class="car-list" id="car-list">

        <div class="container" v-if="type === 'rent'">
            <div class="class-selector" v-dragscroll="dragScrollEnable" @dragscrollstart="blockClick"
                 @dragscrollend="enableClick">
                <button type="button" :class="{'--active': params.selected_class === null,'--block-click': scrollStart}"
                        @click="setKlass(null)"
                        class="--class-button">{{ $t('Все') }}
                </button>
                <button type="button" @click="setKlass(klass.id)"
                        :class="{'--active': params.selected_class === klass.id,'--block-click': scrollStart}"
                        class="--class-button"
                        :data-class_id="klass.id"
                        :data-class_idx="idx"
                        :disabled="typeof klass.disabled !== 'undefined' && klass.disabled === 1"
                        v-for="(klass, idx) in klassList">
                    {{ klass.locale }}
                </button>
            </div>
            <div class="row" style="gap: 24px 0;">
                <div class="col-xl-3 col-lg-4 col-md-4 d-flex align-items-stretch flex-column"
                     v-for="(car, idx) in cars"
                     v-if="car.enabled === 1 && (params.selected_class === null || includeClass(car))"

                     :data-car_id="car.id"
                >
                    <div class="car-list--card">
                        <div class="car-list--card-image text-center">
                            <img :src="'/storage/'+car.image">
                        </div>
                        <div class="car-list--card-name">{{ car.name }}</div>
                        <div class="car-list--card-specs">{{ car.specs }}</div>
                        <div class="car-list--card-footer">
                            <div class="car-list--card-footer-price">
                                <div class="price">
                                    <span>{{ getSum(car.price) + params.delivery }} €</span>

                                    <button
                                        @click="openPopover"
                                        type="button" class="--popover-button">
                                    </button>
                                    <div class="price-popover">
                                        <div class="price-popover-subtext">
                                            <span>{{ params.total_days }} {{
                                                    numFormat(params.total_days, ['день', 'дня', 'дней'])
                                                }} × {{ getPrice(car.price) }} €</span>
                                            <span>{{ getSum(car.price) }} €</span>
                                        </div>
                                        <div class="price-popover-subtext">

                                            <span>{{ $t("carlist.delivery") }}</span>
                                            <span>{{ params.delivery }} €</span>
                                        </div>
                                        <div class="price-popover-text">

                                            <span>{{ $t("carlist.sum") }}</span>
                                            <span>{{ getSum(car.price) + params.delivery }} €</span>
                                        </div>
                                        <hr>
                                        <div class="price-popover-subtext">

                                            <span>{{ $t("carlist.deposit") }}</span>
                                            <span>{{ car.deposit }} €</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="price-day">{{ getPrice(car.price) }} € {{$t("в")}} {{ $t("день") }}</div>
                            </div>
                            <button type="button" @click="selectCar(car.id)" class="--button">{{
                                    $t("carlist.rent")
                                }}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-if="type === 'transfer'">
            <div class="row" style="gap: 24px 0;">
                <div class="col-lg-3 col-md-4 d-flex align-items-stretch flex-column"
                     v-for="(transfer, idx) in options.transfers"
                >
                    <div class="car-list--card">
                        <div class="car-list--card-image text-center">
                            <img :src="'/storage/'+transfer.image">
                        </div>
                        <div class="car-list--card-name">{{ transfer.locale }}</div>
                        <div class="car-list--card-footer">
                            <button type="button" @click="selectTransfer(transfer.id)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#registerModal"
                                    class="--button"> {{ $t('Узнать стоимость') }}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {dragscroll} from 'vue-dragscroll'

export default {
    directives: {
        dragscroll
    },
    name: "CarList",
    data() {
        return {
            scrollStart: false,
			dragScrollEnable: false
        }
    },
    computed: {
        type() {
            return this.$store.state.type;
        },
        options() {
            return this.$store.state.options;
        },
        params() {
            return this.$store.state.params;
        },
        init() {
            return this.$store.state.init;
        },
        cars() {
            return this.$store.getters.getCars;
        },
        klassList() {
            return this.$store.getters.getKlass;
        }
    },
    methods: {
        blockClick(event) {
            this.scrollStart = true;
        },
        enableClick(event) {
            this.scrollStart = false;
        },
        setKlass(klass) {
            this.$store.commit('setKlass', klass);
        },
        getSum(price) {
            let sum = price * this.params.total_days;

            if (this.params.discount > 0)
                sum = sum - (sum * this.params.discount / 100);

            return sum;
        },
        getPrice(price) {
            let sum = price;
            if (this.params.discount > 0)
                sum = sum - (sum * this.params.discount / 100);
            return sum;
        },
        openPopover(e) {
            let elem = e.target;
            elem.addEventListener('click', (event) => {
                elem.classList.toggle('--open')
            })
            let popover = bootstrap.Popover.getOrCreateInstance(elem, {
                html: true,
                placement: 'bottom',
                content: elem.nextElementSibling
            })
            popover.toggle();

        },
        selectCar(id) {
            this.$store.commit('setCar', id);
            let modalElem = document.getElementById('registerModal');
            if (modalElem) {
                let modal = bootstrap.Modal.getOrCreateInstance(modalElem);
                modal.show();
            }

        },
        selectTransfer(id) {
            this.$store.commit('selectTransfer', id);
            /*let modalElem = document.getElementById('registerModal');
            if (modalElem) {
                let modal = bootstrap.Modal.getOrCreateInstance(modalElem);
                modal.show();
            }*/

        },
        includeClass(car) {
            if (this.params.selected_class === null)
                return true;
            let selectedClass = this.params.selected_class;
            let classInclude = false;
            car.classes.forEach((elem) => {
                if (elem.id === selectedClass)
                    classInclude = true;
            })
            return classInclude;


        },
        numFormat(int, words) {
            let $num = int % 100;
            if ($num > 19) {
                $num = $num % 10;
            }

            let $out = '';
            switch ($num) {
                case 1:
                    $out += this.$t(words[0]);
                    break;
                case 2:
                case 3:
                case 4:
                    $out += this.$t(words[1]);
                    break;
                default:
                    $out += this.$t(words[2]);
                    break;
            }
            return $out;
        }
    },
    mounted() {

    /*
    const slider = document.querySelector('.class-selector');
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 2; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            console.log(walk);
        });
		*/

		const vm = this;

		if(window.innerWidth < 992)
				vm.dragScrollEnable = true;

		window.addEventListener("resize", (e)=>{
			vm.dragScrollEnable = window.innerWidth < 992;
		});




    },
    watch: {
        init() {
            /*const popoverButtons = document.querySelectorAll('[data-bs-toggle="popover"]');
            popoverButtons.forEach((elem) => {
                elem.addEventListener('click', (event) => {
                    elem.classList.toggle('--open')
                })
                new bootstrap.Popover(elem, {
                    html: true,
                    content: elem.nextElementSibling
                })
            });*/
        }
    }
}
</script>

<style scoped lang="scss">
.--block-click {
    pointer-events: none;
}
</style>
