<template>
    <div class="modals-container">
        <div class="modal fade registerModal" id="registerModal" tabindex="-1" aria-labelledby="registerModal"
             aria-hidden="true">
            <div class="modal-dialog" :class="{'modal-xl': type === 'rent'}">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"
                            v-if="step === 1 && type === 'rent'">{{ $t('carlist.title_rent') }}</h5>
                        <h5 class="modal-title" id="exampleModalLabel" v-if="step === 1 && type === 'transfer'">
                            {{ $t('carlist.title_transfer') }}</h5>
                        <h5 class="modal-title" style="display: flex; align-items: center; gap: 16px"
                            id="exampleModalLabel"
                            v-if="step === 2">

                            <svg style="cursor: pointer;" @click="prevStep" width="24" height="25" viewBox="0 0 24 25"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.828 11.4999H20V13.4999H7.828L13.192 18.8639L11.778 20.2779L4 12.4999L11.778 4.72192L13.192 6.13592L7.828 11.4999Z"
                                    fill="#00C853"/>
                            </svg>

                            {{ $t('Ввод данных') }}
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="rent-form">
                            <div class="rent-form-steps">
                                <div v-if="step === 1" class="selected-step"
                                     :class="{'--transfer': type === 'transfer'}">
                                    <div class="selected-car" v-if="type === 'rent'">
                                        <p class="selected-title" v-if="car">{{ car.name }}</p>
                                        <div class="selected-car-block" v-if="car">
                                            <div class="selected-car-image">
                                                <img :src="'/storage/'+car.image">
                                            </div>
                                            <div class="selected-car-specs">
                                                <div class="selected-car-specs-elem">
                                                    <span>{{ $t("carlist.gearbox") }}</span>
                                                    <span></span>
                                                    <span>{{ gerbox.locale }}</span>
                                                </div>
                                                <div class="selected-car-specs-elem">
                                                    <span>{{ $t("carlist.engine") }}</span>
                                                    <span></span>
                                                    <span>{{ engine.locale }}</span>
                                                </div>
                                                <div class="selected-car-specs-elem">
                                                    <span>{{ $t("carlist.engine_capacity") }}</span>
                                                    <span></span>
                                                    <span>{{ car.engine_capacity }}</span>
                                                </div>
                                                <div class="selected-car-specs-elem">
                                                    <span>{{ $t("carlist.car_capacity") }}</span>
                                                    <span></span>
                                                    <span>{{ capacity.locale }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="selected-car" v-if="type === 'transfer' && params.transfer">
                                        <p class="selected-title">{{ params.transfer.locale }}</p>
                                        <div class="selected-car-block">
                                            <div class="selected-car-image">
                                                <img :src="'/storage/'+params.transfer.image">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="selected-dates">
                                        <p class="selected-title">{{ $t('Даты') }}</p>
                                        <div class="period-buttons-from">
                                            <p class="period-buttons-title" v-if="type==='rent'">{{
                                                    $t('Получение')
                                                }}</p>
                                            <p class="period-buttons-title" v-if="type==='transfer'">{{
                                                    $t('Откуда')
                                                }}</p>
                                            <div class="header-filter-content">
                                                <div class="--city-select">
                                                    <v-select
                                                        :reduce="option => option.id"
                                                        :value="params.from_city"
                                                        :options="options.city"
                                                        :selectable="(option) => !option.heading"
                                                        v-model="params.from_city">
                                                        <template #option="{ label, tag, heading }">
                                                            <span v-if="heading" class="--select-heading">{{
                                                                    label
                                                                }}</span>
                                                            <span v-else>{{ label }}</span>
                                                        </template>
                                                    </v-select>
                                                </div>
                                                <div class="--date-select">
                                                    <div class="--date-input">
                                                        <flat-pickr :config="dateConfig" class="--input"
                                                                    v-model="params.from_date"/>
                                                    </div>
                                                </div>
                                                <div class="--time-select">
                                                    <v-select
                                                        :searchable="false"
                                                        :value="params.from_time"
                                                        :options="options.time"
                                                        v-model="params.from_time"
                                                    ></v-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="period-buttons-to">
                                            <p class="period-buttons-title" v-if="type==='rent'">{{ $t('Возврат') }}</p>
                                            <p class="period-buttons-title" v-if="type==='transfer'">{{
                                                    $t('Куда')
                                                }}</p>
                                            <div class="header-filter-content">
                                                <div class="--city-select">
                                                    <v-select
                                                        :reduce="option => option.id"
                                                        :value="params.to_city"
                                                        :options="options.city"
                                                        :selectable="(option) => !option.heading"
                                                        v-model="params.to_city">
                                                        <template #option="{ label, tag, heading }">
                                                            <span v-if="heading" class="--select-heading">{{
                                                                    label
                                                                }}</span>
                                                            <span v-else>{{ label }}</span>
                                                        </template>
                                                    </v-select>
                                                </div>
                                                <div class="--date-select" v-if="type === 'rent'">
                                                    <div class="--date-input">
                                                        <flat-pickr :config="dateConfig" class="--input"
                                                                    v-model="params.to_date"/>
                                                    </div>
                                                </div>
                                                <div class="--time-select" v-if="type === 'rent'">
                                                    <v-select
                                                        :searchable="false"
                                                        :value="params.to_time"
                                                        :options="options.time"
                                                        v-model="params.to_time"
                                                    ></v-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="type === 'rent'" class="selected-insurance">
                                        <p class="selected-title">{{ $t('Страхование') }}</p>
                                        <div class="selected-insurance-elem" v-for="(insur, idx) in insurance">
                                            <div class="--checkbox-block">
                                                <input :id="'order_insurance_'+insur.id"
                                                       type="checkbox" :checked="insur.required"
                                                       :disabled="insur.required === 1" v-model="params.insurance"
                                                       name="insurance[]"
                                                       :value="insur.id">
                                                <label :for="'order_insurance_'+insur.id" class="--radio-button">
                                                    {{ insur.locale }}
                                                </label>
                                            </div>
                                            <span>{{ insur.price }} €</span>
                                        </div>
                                    </div>
                                    <div v-if="type === 'rent'" class="selected-options">
                                        <p class="selected-title">{{ $t('Опции') }}</p>
                                        <div class="selected-options-list">
                                            <div class="selected-options-elem" v-model="params.additional"
                                                 v-for="(add, idx) in additional">

                                                <div class="--checkbox-block">
                                                    <input :id="'order_add_'+add.id"
                                                           type="checkbox"
                                                           v-model="params.additional"
                                                           name="options[]"
                                                           :value="add.id">
                                                    <label :for="'order_add_'+add.id" class="--radio-button">
                                                        {{ add.locale }}
                                                    </label>
                                                </div>


                                                <span>{{ add.price }} €</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button @click="nextStep" v-if="type === 'transfer'" class="--button" type="button">
                                        {{ $t('Узнать стоимость') }}
                                    </button>
                                </div>
                                <div v-if="step === 2" class="information-step">
                                    <div class="selected-car">
                                        <p class="selected-title" v-if="type==='rent'">{{ $t('carlist.driver') }}</p>
                                        <p class="selected-title" v-if="type==='transfer'">{{
                                                $t('carlist.personal')
                                            }}</p>
                                        <div class="inputs-block">
                                            <label>
                                                {{ $t('carlist.fname') }}
                                                <span class="input-errors"
                                                      v-for="error of v$.personal.first_name.$errors"
                                                      :key="error.$uid">
                                            </span>
                                                <input class="--input" v-model="personal.first_name" name="first_name"
                                                       type="text" required>
                                            </label>
                                            <label v-if="type==='rent'">
                                                {{ $t('carlist.lname') }}
                                                <span class="input-errors"
                                                      v-for="error of v$.personal.last_name.$errors"
                                                      :key="error.$uid">
                                            </span>
                                                <input class="--input" v-model="personal.last_name" name="last_name"
                                                       type="text" required>
                                            </label>
                                            <label v-if="type==='rent'">
                                                Email
                                                <span class="input-errors" v-for="error of v$.personal.email.$errors"
                                                      :key="error.$uid">
                                            </span>
                                                <input class="--input" v-model="personal.email" name="email"
                                                       type="text" required>
                                            </label>
                                            <label>
                                                {{ $t('carlist.phone') }}
                                                <span class="input-errors" v-for="error of v$.personal.phone.$errors"
                                                      :key="error.$uid">
                                            </span>
                                                <input class="--input" v-model="personal.phone" name="phone"
                                                       type="text" required>
                                            </label>
                                        </div>
                                        <p class="checkbox-block-title" v-html="$t('carlist.messangers_text')"></p>
                                        <div class="checkbox-block">
                                            <div class="--checkbox-block">
                                                <input id="order_messenger_whatsup" type="checkbox" name="messengers[]"
                                                       value="WhatsApp" v-model="personal.messengers">
                                                <label for="order_messenger_whatsup"
                                                       class="--radio-button">WhatsApp</label>
                                            </div>
                                            <div class="--checkbox-block">
                                                <input id="order_messenger_viber" type="checkbox" name="messengers[]"
                                                       value="Viber" v-model="personal.messengers">
                                                <label for="order_messenger_viber"
                                                       class="--radio-button">Viber</label>
                                            </div>
                                            <div class="--checkbox-block">
                                                <input id="order_messenger_telegram" type="checkbox" name="messengers[]"
                                                       value="Telegram" v-model="personal.messengers">
                                                <label for="order_messenger_telegram"
                                                       class="--radio-button">Telegram</label>
                                            </div>
                                        </div>
                                        <label class="--text-area">
                                            {{ $t('carlist.additionals') }}
                                            <textarea class="--input" v-model="personal.additional" name="additional"
                                                      rows="4"></textarea>
                                        </label>

                                        <div class="rules-block">

                                            <div class="--checkbox-block mb-2" v-if="type==='rent'">
                                                <input id="order_rules" type="checkbox" name="rules"
                                                       value="true" v-model="personal.rules"
                                                       required
                                                       :class="{'--errors': v$.personal.rules.$errors.length}"
                                                >
                                                <label for="order_rules" class="--radio-button">
                                                    <span v-html="$t('carlist.rules', {link: '/rules'})"></span>
                                                </label>
                                            </div>
                                            <!--
                                                                                        <label class="&#45;&#45;radio-button" v-if="type==='rent'">
                                                                                            <input required
                                                                                                   :class="{'&#45;&#45;errors': v$.personal.rules.$errors.length}"
                                                                                                   type="checkbox" v-model="personal.rules" name="rules"
                                                                                                   value="true">
                                                                                            <span v-html="$t('carlist.rules', {link: '#'})"></span>
                                                                                        </label>-->


                                            <!--                                            <label class="&#45;&#45;radio-button">
                                                                                            <input :class="{'&#45;&#45;errors': v$.personal.politics.$errors.length}"
                                                                                                   required
                                                                                                   type="checkbox" v-model="personal.politics" name="politics"
                                                                                                   value="true">
                                                                                            <span v-html="$t('carlist.policits', {link: '#'})"></span>
                                                                                        </label>-->

                                            <div class="--checkbox-block">
                                                <input id="order_politics" type="checkbox" name="politics"
                                                       value="true" v-model="personal.politics"
                                                       required
                                                       :class="{'--errors': v$.personal.politics.$errors.length}"
                                                >
                                                <label for="order_politics" class="--radio-button">
                                                    <span v-html="$t('carlist.policits', {link: '/politics'})"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <button :disabled="sendForm" @click="sendRequest" v-if="type === 'transfer'"
                                                class="--button mt-3"
                                                type="button">
                                            {{ $t('carlist.send') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="type === 'rent'" class="rent-form-sum">
                                <div class="rent-form-sum-content" v-if="car">
                                    <p class="rent-form-sum-title">{{ $t('carlist.sum') }}</p>
                                    <hr>
                                    <p class="rent-form-sum-subtitle">{{ $t('main') }}</p>
                                    <div class="rent-form-sum-elem">
                                    <span>{{
                                            $t('carlist.rent_text', {
                                                days: params.total_days,
                                                text: numFormat(params.total_days, ['день', 'дня', 'дней'])
                                            })
                                        }}</span>
                                        <span>{{ getSum(car.price) }} €</span>
                                    </div>
                                    <div class="rent-form-sum-elem">
                                        <span>{{ $t("carlist.delivery") }}</span>
                                        <span>{{ params.delivery }} €</span>
                                    </div>
                                    <hr>
                                    <p class="rent-form-sum-subtitle">{{ $t('Страхование') }}</p>

                                    <div
                                        v-html="printOption(sins, 'ins')"
                                        class="rent-form-sum-elem" v-for="(sins, idx) in params.insurance">

                                    </div>

                                    <hr>
                                    <p class="rent-form-sum-subtitle">{{ $t('Опции') }}</p>
                                    <div v-html="printOption(addit, 'add')" class="rent-form-sum-elem"
                                         v-for="(addit, idx) in params.additional">

                                    </div>
                                    <hr>
                                    <p class="rent-form-sum-subtitle">{{ $t('Итого') }}</p>
                                    <div class="rent-form-sum-elem">
                                        <span>{{ $t('Всего к оплате') }}</span>
                                        <span>{{ params.sum }} €<sup>*</sup></span>
                                    </div>
                                    <div class="rent-form-sum-elem">
                                        <span>+ {{ $t('deposit') }}</span>
                                        <span>{{ car.deposit }} €<sup>**</sup></span>
                                    </div>
                                    <div class="rent-form-sum-button">
                                        <div class="rent-form-sum-subtext">
                                            <p><sup>*</sup>{{ $t('carlist.pay_info') }}</p>
                                            <p><sup>**</sup>{{ $t('carlist.deposit_info') }}</p>
                                        </div>
                                        <button type="button" v-if="step === 1" @click="nextStep" class="--button">
                                            {{ $t('Продолжить') }}
                                        </button>
                                        <button :disabled="sendForm" type="button" v-if="step === 2"
                                                @click="sendRequest" class="--button">
                                            {{ $t('Забронировать') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade sendModal" id="sendModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="type === 'rent'">
                        <div class="selected-car" v-if="type === 'rent' && car">
                            <div class="selected-car-image">
                                <img :src="'/storage/'+car.image">
                            </div>
                            <p class="selected-title" v-if="car">{{ car.name }} <br> {{ $t('успешно забронирован!') }}
                            </p>
                        </div>
                        <div class="rent-param" v-if="from_city && car">
                            <p><span>{{ $t('Получение') }}:</span> {{ from_city.label }}, {{
                                    moment(params.from_date).format('DD.MM.YYYY')
                                }},
                                {{ params.from_time }}</p>
                            <p><span>{{ $t('К оплате') }}:</span> {{ params.sum }} € (+ {{ $t('carlist.deposit') }}
                                {{ car.deposit }} €)</p>
                        </div>
                        <div class="rent-text">
                            {{ $t('rent_text') }}
                        </div>
                    </div>
                    <div class="modal-body" v-if="type === 'transfer'">
                        <div class="selected-car" v-if="type === 'transfer' && params.transfer">
                            <div class="selected-car-image">
                                <img :src="'/storage/'+params.transfer.image">
                            </div>
                            <p class="selected-title">
                                {{ $t('transfer_popup', {tariff: params.transfer.locale}) }}
                            </p>
                        </div>
                        <div class="rent-text">
                            {{ $t('transfer_text') }}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="--button" data-bs-dismiss="modal" aria-label="Close">OK</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import {required, email, sameAs} from '@vuelidate/validators'

import flatPickr from 'vue-flatpickr-component';
import moment from "moment/moment";

export default {
    name: "OrderForm",
    components: {flatPickr},
    setup: () => ({v$: useVuelidate()}),
    data() {
        return {
            step: 1,
            sendForm: false,
            params: this.$store.state.params,
            personal: this.$store.state.personal
        }
    },
    validations() {
        /* return {
             personal: {
                 first_name: {required},
                 last_name: {required},
                 phone: {required},
                 email: {required, email},
                 personal: {required},
                 rules: {required},
             },
         }*/
        const personalRules = {
            personal: {
                phone: {required},
                first_name: {required},
                politics: {
                    checked(val) {
                        return val
                    }
                },
            }
        }
        if (this.type === 'rent') {
            personalRules.personal.last_name = {required};
            personalRules.personal.email = {required, email};
            personalRules.personal.rules = {
                checked(val) {
                    return val
                }
            };
        }
        return personalRules;
    },
    mounted() {
        console.log(this.insurance)
    },
    methods: {
        printOption(id, type) {
            let html = '';
            if (type === 'ins') {
                let insure = this.$store.getters.getInsure(id);
                if (insure)
                    html = `<span>${insure.locale}</span><span>${insure.price} €</span>`;
            }
            if (type === 'add') {
                let addit = this.$store.getters.getAdditional(id);
                if (addit)
                    html = `<span>${addit.locale}</span><span>${addit.price} €</span>`;
            }
            return html;

        },
        getSum(price) {
            let sum = price * this.params.total_days;

            if (this.params.discount > 0)
                sum = sum - (sum * this.params.discount / 100);

            return sum;
        },
        getPrice(price) {
            let sum = price;
            if (this.params.discount > 0)
                sum = sum - (sum * this.params.discount / 100);
            return sum;
        },
        nextStep() {
            this.step++;
        },
        prevStep() {
            this.step--;
        },
        async sendRequest(e) {

            if (this.sendForm === true)
                return;

            this.sendForm = true;

            const vm = this;
            let sendModalElem = document.getElementById('sendModal');
            let sendModal = bootstrap.Modal.getOrCreateInstance(sendModalElem);

            let registerModalElem = document.getElementById('registerModal');
            let registerModal = bootstrap.Modal.getOrCreateInstance(registerModalElem);

            const isFormCorrect = await this.v$.$validate()
            if (isFormCorrect) {
                axios.post('/send', {personal: this.personal, params: this.params, type: this.type})
                    .then((response) => response.data)
                    .then((data) => {

                        vm.sendForm = false;

                        if (data.status === 'success') {
                            registerModal.hide();
                            sendModal.show();
                        } else {
                            vm.$swal({
                                icon: "error",
                                text: vm.$t('send_error')
                            });
                        }
                    }).catch((error) => {
                    vm.sendForm = false;
                    vm.$swal({
                        icon: "error",
                        text: vm.$t('send_error')
                    });
                });

            } else
                this.sendForm = false;
        },
        numFormat(int, words) {
            let $num = int % 100;
            if ($num > 19) {
                $num = $num % 10;
            }

            let $out = '';
            switch ($num) {
                case 1:
                    $out += this.$t(words[0]);
                    break;
                case 2:
                case 3:
                case 4:
                    $out += this.$t(words[1]);
                    break;
                default:
                    $out += this.$t(words[2]);
                    break;
            }
            return $out;
        }

    },
    watch: {
        params: {
            handler(val) {
                this.$store.commit('setParams', this.params);
                this.$store.commit('calculateSum')
            }
            ,
            deep: true
        },
        personal: {
            handler(val) {
                this.$store.commit('setPersonal', this.personal)
            }
            ,
            deep: true
        },
    },
    computed: {
        moment() {
            return moment
        },
        from_city() {
            return this.$store.getters.getCityFrom;
        },
        car() {
            return this.$store.getters.getCar;
        },
        gerbox() {
            return this.$store.getters.getGearbox(this.car.gearbox_id)
        },
        engine() {
            return this.$store.getters.getEngine(this.car.engine_id)
        },
        capacity() {
            return this.$store.getters.getCapacity(this.car.capacity_id)
        },
        type() {
            return this.$store.state.type;
        },
        options() {
            return this.$store.state.options;
        },
        dateConfig() {
            return this.$store.state.dateConfig;
        },
        insurance() {
            return this.$store.state.options.insurance;
        },
        additional() {
            return this.$store.state.options.additional;
        }
    }
}
</script>

<style scoped  lang="scss">
.selected-car-image{
	img{
	 max-width: 234px;
	}
}
</style>
