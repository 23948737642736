<template>
    <div :id="id"></div>
</template>

<script>

export default {
    name: "ItcSelect",
    props: ['options', 'value'],
    data() {
        return {
            id: null,
            select: null
        }
    },
    mounted() {
        this.id = this.$uuid.v1();
        this.initSelect();
    },
    methods: {
        initSelect() {
            const vm = this;
            this.select = new ItcCustomSelect(`#${this.id}`, {
                data: vm.options,
                onSelected(select, option) {
                    vm.$emit('input', itc.select.value)
                    vm.$emit('changeValue');
                }
            })
        }
    },
    watch: {
        value(value) {
            this.select.value = value;
        }
    }
}
</script>

<style scoped>

</style>
